import type { DBImage, DisplayImage } from '@tn/shared';

export const getImageUrl = (image: DBImage) => {
  if (image?.gcsPath) {
    return `/${image.gcsPath}`;
  }

  return null;
};

export const getImageProvider = (image: DBImage) => {
  if (image?.gcsPath) {
    return 'bunny';
  }

  return undefined;
};

interface ImageOptions {
  alt?: string;
}

export const getImageObject = (
  image: DBImage | undefined,
  { alt = '' }: ImageOptions
) => {
  if (!image) {
    return undefined;
  }

  const path = getImageUrl(image);
  const provider = getImageProvider(image);

  return {
    path,
    provider,
    alt,
  } as DisplayImage;
};
